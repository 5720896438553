import axios from 'axios';

/**
 * Make axios request
 * @param {Object} requestParams 
 * @param {Object} clientParams
 * @returns {Promise}
 */
const makeRequest = (requestParams, clientParams) => {
  var axiosParams = {
    method: 'get',
    url: `${clientParams.endpoint}${requestParams.path}`,    
    headers: {},
    data: requestParams.data,
    json: true
  }

  if (requestParams.method) {
    axiosParams.method = requestParams.method;
  }

  if (requestParams.params) {
    axiosParams.params = requestParams.params;
  }

  if (requestParams.token) {
    if (requestParams.useAuth !== false) {
      axiosParams.headers['Authorization'] = `Bearer ${requestParams.token}`;
    }
  }

  return new Promise((resolve, reject) => {
    axios(axiosParams)
      .then((response) => {
        resolve({ status: response.status, data: response.data });
      })
      .catch((error) => {
        if (error.response) {
          return reject({ status: error.response.status, data: error.response.data });
        }

        return reject(error);
      });
  });
}

/**
 * Make HTTP client
 * @param {Object} clientParams 
 * @returns {Object}
 */
const makeClient = (clientParams) => {
  if (!clientParams.endpoint) {
    clientParams.endpoint = 'https://accounts.esl.geo-pointer.com/v1'
  }

  var api = {
    token: null,
    roles: {
      READ: 'READ',
      WRITE: 'WRITE',
      ADMIN: 'ADMIN',
      OWNER: 'OWNER'
    },
    setToken: function (newToken) {
      this.token = newToken;
      return this
    }
  }

  /**
   * Sign request
   * @param {Object} requestParams
   * @returns {Object} signed requestParams
   */
  const withAuth = (requestParams) => {
    return {
      ...requestParams,
      token: api.token,
      useAuth: true
    }
  }

  api.public = api.public || {};
  api.user = api.user || {};
  api.user.invites = api.user.invites || {};
  api.user.projects = api.user.projects || {};
  api.user.projects.invites = api.user.projects.invites || {};
  api.user.projects.members = api.user.projects.members || {};

  // PUBLIC

  /**
   * Find project
   * @param {Object} params
   * @param {String} params.id
   */
  api.public.getProject = function (params) {
    const { id } = params;

    var requestParams = {
      path: `/public/project/${id}`
    };

    return makeRequest(requestParams, clientParams);
  }

  /**
   * Find user
   * @param {Object} params
   * @param {String} params.id
   */
  api.public.getUser = function (params) {
    const { id } = params;

    var requestParams = {
      path: `/public/user/${id}`,
    };

    return makeRequest(requestParams, clientParams);
  }

  /**
   * Find user
   * @param {Object} params
   * @param {String} params.id
   */
  api.public.getUser = function (params) {
    const { id } = params;

    var requestParams = {
      path: `/public/user/${id}`,
    };

    return makeRequest(requestParams, clientParams);
  }

  /**
   * Find invite
   * @param {Object} params
   * @param {String} params.code
   */
  api.public.getInvite = function (params) {
    const { code } = params;

    var requestParams = {
      path: `/public/invite`,
      params: {
        code: code
      }
    }

    return makeRequest(requestParams, clientParams);    
  }


  // USER
  
  /**
   * Return user credentials
   */
  api.user.getCredentials = function () {

    var requestParams = withAuth({
      method: 'GET',
      path: `/user/me`
    });

    return makeRequest(requestParams, clientParams);
  }


  // USER.INVITES

  /**
   * List all user invites
   * @param {Object} params
   * @param {Number} params.max
   * @param {Number} params.offset
   */
  api.user.invites.getAll = function (params) {

    var requestParams = withAuth({
      method: 'GET',
      path: `/user/invites`
    });

    return makeRequest(requestParams, clientParams);
  }

  /**
   * Invite answer
   * @param {Object} params
   * @param {String} params.code
   * @param {Boolean} params.confirm
   */
  api.user.invites.answer = function (params) {
    const { confirm, code } = params;

    var requestParams = withAuth({
      method: 'post',
      path: `/user/invites/answer`,
      data: {
        token: code,
        confirm: Boolean(confirm)
      }
    });

    return makeRequest(requestParams, clientParams);
  }


  // USER.PROJECTS

  /**
   * Return project details
   * @param {Object} params
   * @param {String} params.id
   */
  api.user.projects.getOne = function ({ id }) {

    var requestParams = withAuth({
      path: `/user/projects/${id}`
    })

    return makeRequest(requestParams, clientParams);
  }

  /**
   * Return project list
   * @param {Object} params
   * @param {Number} params.max
   * @param {Number} params.offset
   */
  api.user.projects.getAll = function (params) {

    var requestParams = withAuth({
      path: '/user/projects'
    })

    return makeRequest(requestParams, clientParams);
  }


  // USER.PROJECTS.INVITES

  /**
   * Invite non-user
   * @param {Object} params
   * @param {String} params.projectId Project
   * @param {String} params.address User email address
   * @param {String} params.permission User permission
   */
  api.user.projects.invites.create = function (params) {
    const { projectId, address, permission } = params;

    if (!projectId) {
      throw new Error('Missing parameter "projectId"');
    }

    if (!address) {
      throw new Error('Missing parameter "address"');
    }

    if (!permission) {
      throw new Error('Missing parameter "permission"');
    }

    var requestParams = withAuth({
      method: 'POST',
      path: `/user/projects/${projectId}/invites`,
      data: {
        address,
        permission: permission.toUpperCase()
      }
    });

    return makeRequest(requestParams, clientParams);
  }

  /**
   * List projects invites
   * @param {Object} params
   * @param {String} params.projectId Project
   * @param {Number} params.max
   * @param {Number} params.offset
   */
  api.user.projects.invites.getAll = function (params) {
    const { projectId } = params;

    if (!projectId) {
      throw new Error('Missing parameter "projectId"');
    }

    var requestParams = withAuth({
      method: 'GET',
      path: `/user/projects/${projectId}/invites`
    });

    return makeRequest(requestParams, clientParams);
  }

  /**
   * Delete invite
   * @param {Object} params
   * @param {String} params.projectId Project
   * @param {String} params.id Invite ID
   */
  api.user.projects.invites.delete = function (params) {
    const { projectId, id } = params;

    if (!projectId) {
      throw new Error('Missing parameter "projectId"');
    }

    if (!id) {
      throw new Error('Missing parameter "id"');
    }

    var requestParams = withAuth({
      method: 'delete',
      path: `/user/projects/${projectId}/invites/${id}`
    });

    return makeRequest(requestParams, clientParams);
  }

  // USER.PROJECTS.MEMBERS

  /**
   * List projects members
   * @param {Object} params
   * @param {String} params.projectId Project
   * @param {Number} params.max
   * @param {Number} params.offset
   */
  api.user.projects.members.getAll = function (params) {
    const { projectId } = params;

    if (!projectId) {
      throw new Error('Missing parameter "projectId"');
    }

    var requestParams = withAuth({
      method: 'GET',
      path: `/user/projects/${projectId}/members`
    });

    return makeRequest(requestParams, clientParams);
  }

  return api;
}

export default makeClient;
//module.exports = makeClient;